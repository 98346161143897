<template>
  <div style="width: 100%; height: auto" class="package-container">
    <div class="spinner-grow spinner" v-if="loading">
      <span class="sr-only"></span>
    </div>
    <div class="tours__container" v-if="!loading">
      <!-- <navbar /> -->
      <div class="container">
        <div class="row">
          <div class="col-md-12" style="display:flex; justify-content: center">
            <autocomplete :search="search" class="tours__search" @submit="handleSearch"></autocomplete>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="tours col-md-9" v-for="item in allpacks" :key="item.PckgId + '-' + searchItem.key">
            <div class="row p0 m0">
              <div class="tours__wrap">
                <div class="col-md-4">
                  <div class="tours__image"><img :src="getImage(item)" /></div>
                </div>
                <div class="col-md-6">
                  <div class="tours__content">
                    <div class="row tours__name"><div class="col"><span>{{ item.PckgName }}</span></div></div>
                    <div class="row tours__duration"><div class="col"><span>Duration: {{ item.PckgDuration }} hours</span></div></div>
                    <div class="row tours__details">
                      <div class="col">
                        <button class="tours__description" @click="setData(item.Description)" v-b-modal.description>Description</button>
                      </div>
                      <div class="col">
                        <button class="tours__inclusions" @click="setData(item.Inclusions)" v-b-modal.inclusions>Inclusions</button>
                      </div>
                      <div class="col">
                        <button class="tours__timings" @click="setData(item.Policies)" v-b-modal.policies>Timings</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="price col">
                  <div class="row">

                    <div class="row">
                      <span>AED {{ item.PckgPriceAEDAD }}</span>
                    </div>
                    <div class="row price_viewdetails">
                      <button @click="packageClick(item)">Book Now</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <b-modal id="description" button-size="sm" centered title="Description">
            <p class="my-4" v-html="modalContent"></p>
            <template #modal-footer="{ ok, cancel, hide }">
              <b-button size="md" style="background: #607D8B" @click="ok()">Done</b-button>
            </template>
          </b-modal>
          <b-modal id="inclusions" button-size="sm" centered title="Inclusions">
            <p class="my-4" v-html="modalContent"></p>
            <template #modal-footer="{ ok, cancel, hide }">
              <b-button size="md" style="background: #607D8B" @click="ok()">Done</b-button>
            </template>
          </b-modal>
          <b-modal id="policies" button-size="sm" centered title="Policies">
            <p class="my-4" v-html="modalContent"></p>
            <template #modal-footer="{ ok, cancel, hide }">
              <b-button size="md" style="background: #607D8B" @click="ok()">Done</b-button>
            </template>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import bg1 from "../assets/bg1.jpeg";
import ratings from "../assets/Ratings.png";
import FooterHome from "../components/FooterHome.vue";

export default {
  name: "Packages",
  data() {
    return {
      bg1: bg1,
      ratings: ratings,
      data: {},
      allpacks: {},
      pID: " ",
      pName: " ",
      loading: true,
      searchItem: {
        query: [],
        key: 0
      },
      modalContent: ''
    };
  },
  components: {
    Carousel,
    Slide,
    FooterHome,
  },
  async mounted() {
    this.getAllPackages()
  },

  methods: {

    getImage(data) {
      return `https://www.desertlinktours.com/api/product-images/${data.PckgName}.webp`
    },

    setData: function(data) {
      this.modalContent = data
    },

    async getAllPackages () {
      await this.$store
      .dispatch("packages/getPackages")
      .then((response) => {
        this.loading = false;
        this.allpacks = response.data;
      })
      .catch((error) => console.debug(error));
    },
    packageClick(object) {
      this.$router.push("/packageDetails/" + object.ParentId);
    },
    async search(input) {
      let packages = []

      if (input.length < 1) { return this.getAllPackages() }

      for(let i=0; i < this.allpacks.length; i++) {
        packages.push(this.allpacks[i].PckgName.toLowerCase())
      }
      this.searchItem.query = packages.filter(item => item.startsWith(input))

      return this.searchItem.query

    },
    async handleSearch(result) {
      for(let i=0; i < this.allpacks.length; i++) {
        if(this.allpacks[i].PckgName.toLowerCase() === result) {
          let id = this.allpacks[i].PckgId
          // return this.$router.push({ path: '/packageDetails/' + id})
          this.allpacks = [this.allpacks[i]]
          this.searchItem.key++
        }
      }
    }

  },
};
</script>

<style lang="scss" scoped>

.tours {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  // padding: 1rem 0;


  &__search {
    width: 40rem;
    margin: 2rem 0 0 0;
  }

  &__wrap {
    display: flex;
    padding: 0;
    height: 200px;
    margin: 2.5rem 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  &__image {
    margin: 0;
    padding: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 100%;

    img {
      width: 100%;
      height: 100%;

    }

  }

  &__content {
    padding: 2rem;
	 background: white;
  }

  &__name {
    padding: 4px 0;

    span {
      font-size: 22px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
    }
  }

  &__duration {
    padding: 1rem 0;


    span {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__details {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 0;

    col {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
    }
  }

  &__description, &__inclusions, &__timings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    color: #212121;
    cursor: pointer;
    display: inline-flex;
    fill: #212121;
    font-family: 'Open Sans',sans-serif;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: -.8px;
    line-height: 24px;
    outline: 0;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    &:focus {
      color: #171e29;
    }

    &:hover {
      border-color: #607D8B;
      color: #607D8B;
      fill: #607D8B;
    }

    &:active {
      border-color: #607D8B;
      color: #607D8B;
      fill: #607D8B;
    }

  }

  .price {
    background: #ECEFF1;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;

    :nth-child(1) {
      height: 100%;
      display: flex;
      justify-content: center;

      span {
        display: flex;
        height: 100%;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        font-size: 24px;
        font-weight: 300;
      }
    }

    .row {
      display: flex;
      justify-content: center;
      padding: 0;
      margin: 0;
    }
  }
}

.price_viewdetails {
    background: red;
    padding: 20px 0;
    position: absolute;
    bottom: 0;

    button {
      background: #607D8B;
      border: none;
      padding: 15px 0;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      color: #FFF;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

      &:hover {
        background: #263238;
      }
    }
  }

</style>
